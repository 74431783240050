import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { RootState } from "../Store";
import MainLayout from "../Layouts/MainLayout";
import Input from "../Components/Input";
import useFetch from "../Hooks/useFetch";

import styles from "../Styles/Forms.module.css";
import moment from "moment";

function PlaylistEdit() {
  const navigate = useNavigate();
  const token: any = useSelector((state: RootState) => state.auth.token);

  const { slug: id } = useParams();
  const [cover, setCover] = useState<any>(null);
  const [name, setName] = useState<any>(null);
  const [slug, setSlug] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const { data, error, loading }: any = useFetch(`/playlists/${id}`, token);
  const [songs, setSongs] = useState<any>(null);

  useEffect(() => {
    if (data && data.songs) {
      setSongs(data.songs);
    }
  }, [data, setSongs]);

  useEffect(() => {
    if (data && data.playlist) {
      setName(data.playlist.name);
      setSlug(data.playlist.slug);
      setStartDate(moment(data.playlist.startDate).format("YYYY-MM-DDTHH:mm"));
      setEndDate(moment(data.playlist.endDate).format("YYYY-MM-DDTHH:mm"));
    }
  }, [data, setName, setSlug, setStartDate, setEndDate]);

  const uploadData = async (e: any) => {
    e.preventDefault();
    let formData = new FormData();

    if (cover) {
      formData.append("cover", cover);
    }
    
    if (name) {
      formData.append("name", name);
    }
    
    if (slug) {
      formData.append("slug", slug);
    }
    
    if (startDate) {
      formData.append("startDate", startDate);
    }
    
    if (endDate) {
      formData.append("endDate", endDate);
    }

    try {
      await axios.put(`/playlists/${data.playlist._id}`, formData, {
        headers: {
          Authorization: token,
        },
      });

      navigate("/playlists");
    } catch (error: any) {
      console.error("Error: ", error);
      alert(error.response.message);
    }
  };

  const addSong = () => navigate(`/playlists/${data.playlist._id}/songs/add`);

  const editSong = (id: string) => navigate(`/playlists/${data.playlist._id}/songs/${id}/edit`);

  const deleteSong = async (id: string) => {
    try {
      await axios.delete(`songs/${id}`, {
        headers: {
          Authorization: token,
        },
      });

      const new_songs = songs?.filter((song: any) => {
        return song._id !== id;
      });

      setSongs(new_songs);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <MainLayout>
      <div className={styles.formContainer}>
        {loading ? (
          <h3>Loading...</h3>
        ) : (
          <>
            <header>
              <h1>Edit "{data.playlist.name}" Playlist</h1>
            </header>
            <form onSubmit={uploadData}>
              <div className={styles.formControl}>
                <Input
                  type="file"
                  name="Cover"
                  state={cover}
                  setState={setCover}
                />
              </div>
              <div className={styles.formControl}>
                <Input name="Name" state={name} setState={setName} />
              </div>
              <div className={styles.formControl}>
                <Input name="Slug" state={slug} setState={setSlug} />
              </div>
              <div className={styles.formControl}>
                <Input
                  type="datetime-local"
                  name="Start Date"
                  state={startDate}
                  setState={setStartDate}
                />
              </div>
              <div className={styles.formControl}>
                <Input
                  type="datetime-local"
                  name="End Date"
                  state={endDate}
                  setState={setEndDate}
                />
              </div>
              <div className={styles.formControl}>
                <button className={styles.submitButton}>Edit Playlist</button>
              </div>
            </form>
          </>
        )}
      </div>
      {loading ? (
        <h3>Loading...</h3>
      ) : (
        <>
          <div className={styles.addNew}>
            <p>Add New Songs?</p>
            <button onClick={addSong} className={styles.aquaButton}>Add</button>
          </div>
          {(songs && songs.length > 0) && (
            <table className={styles.table}>
              <thead>
                <tr>
                  <td>Cover</td>
                  <td>Name</td>
                  <td>Artist Name</td>
                  <td>Album Name</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                {songs && songs.map((song: any, idx: number) => (
                  <tr key={idx}>
                    <td>
                      <img
                        src={song.cover}
                        alt={song.name}
                      />
                    </td>
                    <td>{song.name}</td>
                    <td>{song.albumName}</td>
                    <td>{song.artistName}</td>
                    <td>
                      <button onClick={() => editSong(song._id)} className={styles.aquaButton}>Edit</button>
                      <button onClick={() => deleteSong(song._id)} className={styles.deleteButton}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </MainLayout>
  );
}

export default PlaylistEdit;
