import React from "react";
import { MdOutlineNotStarted } from "react-icons/md";

import styles from "../Styles/PlaylistCard.module.css";

interface Props {
  id: string;
  image: string;
  name: string;
  assigned: boolean;
  setAssigned: any;
}

function PlaylistCard({ id, image, name, assigned, setAssigned }: Props) {
  return (
    <div className={styles.playlistCard}>
      <img src={image} alt={name} />
      <div className={styles.text}>
        <h3 className={styles.name}>{name}</h3>
        <p className={styles.plays}>
          <span className={styles.playsContainerIcon}>
            <MdOutlineNotStarted />
          </span>
          <span className={styles.playsContainerText}>Playlist</span>
        </p>
      </div>
      <button
        onClick={() => setAssigned(id)}
        className={`${styles.assigningButton} ${assigned && styles.assigned}`}
      >
        {assigned ? "Assigned" : "Assign"}
      </button>
    </div>
  );
}

export default PlaylistCard;
