import MainLayout from '../Layouts/MainLayout'
import styles from '../Styles/Home.module.css'

function Home() {
  return (
    <MainLayout>
      <h1 className={styles.title}>Home</h1>
      <p>You can manage all of the website songs and messages here from the panel.</p>
      <h3 className={styles.subTitle}>What I have permission to manage?</h3>
      <ol>
        <li>Users</li>
        <li>Songs</li>
        <li>Ads (Songs Messages)</li>
      </ol>
    </MainLayout>
  )
}

export default Home