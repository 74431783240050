import React, { useEffect, useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import styles from '../Styles/Playlists.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../Store';
import useFetch from '../Hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Messages() {
  const navigate = useNavigate();
  const token: any = useSelector((state: RootState) => state.auth.token);
  const { data, error, loading }: any = useFetch('/messages', token);
  const [messages, setMessages] = useState<any>(null);

  useEffect(() => {
    setMessages(data);
  }, [data, setMessages]);

  const addMessage = () => navigate('/messages/add');

  const deleteMessage = async (id: string) => {
    try {
      await axios.delete(`messages/${id}`, {
        headers: {
          Authorization: token
        }
      });
      
      const new_messages = messages.filter((message: any) => {
        return message._id !== id;
      });

      setMessages(new_messages);
    } catch (error) {
      console.error(error);
    }
  }

  const editMessage = (id: string) => navigate(`/messages/${id}/edit`);

  return (
    <MainLayout>
      <div className={styles.addNew}>
        <p>Add New Message?</p>
        <button onClick={addMessage} className={styles.aquaButton}>Add</button>
      </div>
      {loading ? (
        <h3>Loading...</h3>
      ) : (
        <table className={styles.table}>
          <thead>
            <tr>
              <td>Ad cover</td>
              <td>Audio name</td>
              <td>Every</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {messages && messages.map((message: any, idx: number) => (
              <tr key={idx}>
                <td><img src={message.cover} alt={message.name} /></td>
                <td>{message.name}</td>
                <td>{message.duration / 60 / 60} hour</td>
                <td>
                  <button onClick={() => editMessage(message._id)} className={styles.aquaButton}>Edit</button>
                  <button onClick={() => deleteMessage(message._id)} className={styles.deleteButton}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </MainLayout>
  )
}

export default Messages