import { useEffect, useState } from "react";
import moment from 'moment';
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import useFetch from "../Hooks/useFetch";
import { RootState } from "../Store";
import MainLayout from "../Layouts/MainLayout";

import styles from '../Styles/Playlists.module.css';

function Playlists() {
  const navigate = useNavigate();
  const token: any = useSelector((state: RootState) => state.auth.token);
  const [playlists, setPlaylists] = useState<any>(null);
  const { data, error, loading }: any = useFetch('/playlists?admin=true', token);
  
  useEffect(() => {
    setPlaylists(data);
  }, [data]);

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`/playlists/${id}`, {
        headers: {
          Authorization: token
        }
      });

      const new_playlists = playlists?.filter((playlist: any) => {
        return playlist._id !== id;
      });

      setPlaylists(new_playlists);
    } catch (e: any) {
      alert(e.response.message);
    }
  }

  const addPlaylist = () => navigate('/playlists/add');

  const editPlaylist = (slug: string) => navigate(`/playlists/${slug}/edit`);

  return (
    <MainLayout>
      <div className={styles.addNew}>
        <p>Add New Playlist?</p>
        <button onClick={addPlaylist} className={styles.aquaButton}>Add</button>
      </div>
      {loading ? (
        <h3>Loading...</h3>
      ) : (
        <table className={styles.table}>
          <thead>
            <tr>
              <td>Cover</td>
              <td>Name</td>
              <td>Slug</td>
              <td>Start Time</td>
              <td>End Time</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {playlists && playlists.map((playlist: any, idx: number) => (
              <tr key={idx}>
                <td>
                  <img
                    src={playlist.cover}
                    alt={playlist.name}
                  />
                </td>
                <td>{playlist.name}</td>
                <td>{playlist.slug}</td>
                <td>{playlist.startDate ? moment(playlist.startDate).format('D/M/YYYY h:mm:s a') : 'N/A'}</td>
                <td>{playlist.endDate ? moment(playlist.endDate).format('D/M/YYYY h:mm:s a') : 'N/A'}</td>
                <td>
                  <button onClick={() => editPlaylist(playlist.slug)} className={styles.aquaButton}>Edit</button>
                  <button onClick={() => handleDelete(playlist._id)} className={styles.deleteButton}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </MainLayout>
  );
}

export default Playlists;
