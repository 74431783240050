import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../Store";
import MainLayout from "../Layouts/MainLayout";
import Input from "../Components/Input";

import styles from "../Styles/Forms.module.css";

function PlaylistAdd() {
  const navigate = useNavigate();
  const token: any = useSelector((state: RootState) => state.auth.token);
  const [cover, setCover] = useState<any>(null);
  const [name, setName] = useState<any>(null);
  const [slug, setSlug] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const uploadData = async (e: any) => {
    e.preventDefault();
    let formData = new FormData();

    formData.append("cover", cover);
    formData.append("name", name);
    formData.append("slug", slug);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    
    try {
      await axios.post("/playlists", formData, {
        headers: {
          Authorization: token
        }
      });

      navigate('/playlists');
    } catch (error: any) {
      console.error("Error: ", error);
      alert(error.response.message);
    }
  };

  return (
    <MainLayout>
      <div className={styles.formContainer}>
        <header>
          <h1>Add Playlist</h1>
        </header>
        <form onSubmit={uploadData}>
          <div className={styles.formControl}>
            <Input type="file" name="Cover" state={cover} setState={setCover} />
          </div>
          <div className={styles.formControl}>
            <Input name="Name" state={name} setState={setName} />
          </div>
          <div className={styles.formControl}>
            <Input name="Slug" state={slug} setState={setSlug} />
          </div>
          <div className={styles.formControl}>
            <Input
              type="datetime-local"
              name="Start Date"
              state={startDate}
              setState={setStartDate}
            />
          </div>
          <div className={styles.formControl}>
            <Input
              type="datetime-local"
              name="End Date"
              state={endDate}
              setState={setEndDate}
            />
          </div>
          <div className={styles.formControl}>
            <button className={styles.submitButton}>Add Playlist</button>
          </div>
        </form>
      </div>
    </MainLayout>
  );
}

export default PlaylistAdd;
