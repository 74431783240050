import React from 'react';

import styles from '../Styles/PlaylistContainer.module.css'

interface Props {
  title?: string;
  children: any;
  description?: string;
  saveSettings: any;
  isSaving: boolean;
}

function PlaylistContainer({ children, title, description, saveSettings, isSaving }: Props) {
  return (
    <div>
      <div className={styles.topPart}>
        {title && (
          <div className={styles.title}>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
        )}
        <div className="right-side">
          <button disabled={isSaving} onClick={saveSettings} className={styles.saveButton}>{isSaving ? "Saving..." : "Save Settings"}</button>
        </div>
      </div>
      <div className={styles.playlistContainerContent}>
        {children}
      </div>
    </div>
  )
}

export default PlaylistContainer