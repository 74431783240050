import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { RootState } from "../../Store";
import MainLayout from "../../Layouts/MainLayout";
import Input from "../../Components/Input";
import useFetch from "../../Hooks/useFetch";

import styles from "../../Styles/Forms.module.css";

function UserEdit() {
  const navigate = useNavigate();
  const token: any = useSelector((state: RootState) => state.auth.token);

  const { id } = useParams();
  const [name, setName] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  const [password, setPassword] = useState<any>(null);
  const [role, setRole] = useState<any>(null);
  const { data, loading }: any = useFetch(`/users/${id}`, token);

  useEffect(() => {
    if (data && data) {
      setName(data?.name);
      setEmail(data?.email);
      setRole(data?.role);
    }
  }, [data, setName, setEmail, setRole]);

  const updateUser = async (e: any) => {
    e.preventDefault();
    try {
      await axios.put(`/users/${id}`, {
        name,
        email,
        password: password ? password : undefined,
        role,
      }, {
        headers: {
          Authorization: token
        }
      });
      navigate("/users");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <MainLayout>
      <div className={styles.formContainer}>
        {loading ? (
          <h3>Loading...</h3>
        ) : (
          <>
            <header>
              <h1>Edit "{data?.name}" User</h1>
            </header>
            <form onSubmit={updateUser}>
              <div className={styles.formControl}>
                <Input name="Name" state={name} setState={setName} required />
              </div>
              <div className={styles.formControl}>
                <Input name="Email" state={email} setState={setEmail} required />
              </div>
              <div className={styles.formControl}>
                <Input
                  type="password"
                  name="Password"
                  state={password}
                  setState={setPassword}
                />
              </div>
              <div className={styles.formControl}>
                <Input
                  type="select"
                  name="Role"
                  state={role}
                  setState={setRole}
                  options={[
                    { name: "Admin", value: "admin" },
                    { name: "User", value: "user" },
                  ]}
                  required
                />
              </div>
              <div className={styles.formControl}>
                <button className={styles.submitButton}>Edit User</button>
              </div>
            </form>
          </>
        )}
      </div>
    </MainLayout>
  );
}

export default UserEdit;
