import { useSelector } from 'react-redux';

import MainLayout from '../../Layouts/MainLayout'
import PlaylistContainer from '../../Components/PlaylistContainer'
import PlaylistCard from '../../Components/PlaylistCard'
import useFetch from '../../Hooks/useFetch';
import { RootState } from '../../Store';
import PlaylistCardSkeleton from '../../Components/PlaylistCardSkeleton';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';

interface Playlist {
  _id: string;
  cover: string;
  name: string;
  slug: string;
}

function AssignPlaylists() {
  const { id } = useParams();
  const [playlists, setPlaylists] = useState<any>([]);
  const [isSaving, setSaving] = useState<boolean>(false);

  const token: any = useSelector((state: RootState) => state.auth.token);
  const { data, error, loading }: any = useFetch('/playlists?admin=true', token);
  const { data: userData, loading: userLoading }: any = useFetch(`/users/${id}`, token);

  useEffect(() => {
    if (userData && !userLoading) {
      setPlaylists(userData.playlists);
    }
  }, [userData]);

  const setAssigned = (id: string) => {
    if (playlists.includes(id)) {
      setPlaylists(playlists.filter((playlist: any) => {
        return playlist !== id;
      }));
    } else {
      setPlaylists([...playlists, id]);
    }
  }

  const saveSettings = async () => {
    setSaving(true);

    try {
      await axios.put(`/users/assign-playlist`, {
        id,
        playlists
      }, {
        headers: {
          Authorization: token
        }
      });

      setSaving(false);
    } catch (e: any) {
      setSaving(false);
      alert(e.response.data.message);
    }
  }

  return (
    <MainLayout>
      <PlaylistContainer title={`Assign Playlists`} description={`Assigning playlists to ${userData?.name}`} saveSettings={saveSettings} isSaving={isSaving}>
        {!loading ? (
          data && data.map((playlist: Playlist, idx: number) => (
            <PlaylistCard
              key={idx}
              id={playlist._id}
              image={playlist.cover}
              name={playlist.name}
              assigned={playlists.includes(playlist._id)}
              setAssigned={setAssigned}
            />
          ))
        ) : (
          [...Array(10)].map((card, idx) => (
            <PlaylistCardSkeleton key={idx} />
          ))
        )}
      </PlaylistContainer>
    </MainLayout>
  )
}

export default AssignPlaylists