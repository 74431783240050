import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../Store";
import MainLayout from "../Layouts/MainLayout";
import Input from "../Components/Input";

import styles from "../Styles/Forms.module.css";

function MessageAdd() {
  const navigate = useNavigate();
  const token: any = useSelector((state: RootState) => state.auth.token);
  const [cover, setCover] = useState<any>(null);
  const [name, setName] = useState<any>(null);
  const [audio, setAudio] = useState<any>(null);
  const [duration, setDuration] = useState<any>(null);

  const uploadData = async (e: any) => {
    e.preventDefault();
    let formData = new FormData();

    formData.append("cover", cover);
    formData.append("name", name);
    formData.append("audio", audio);
    formData.append("duration", duration);
    
    try {
      await axios.post("/messages", formData, {
        headers: {
          Authorization: token
        }
      });

      navigate('/messages');
    } catch (error: any) {
      console.error("Error: ", error);
      alert(error.response.message);
    }
  };

  return (
    <MainLayout>
      <div className={styles.formContainer}>
        <header>
          <h1>Add Message</h1>
        </header>
        <form onSubmit={uploadData}>
          <div className={styles.formControl}>
            <Input type="file" name="Cover" state={cover} setState={setCover} />
          </div>
          <div className={styles.formControl}>
            <Input name="Name" state={name} setState={setName} />
          </div>
          <div className={styles.formControl}>
            <Input type="file" name="Audio" state={audio} setState={setAudio} />
          </div>
          <div className={styles.formControl}>
            <Input name="Repeat Every? (In Seconds)" state={duration} setState={setDuration} />
          </div>
          <div className={styles.formControl}>
            <button className={styles.submitButton}>Add Message</button>
          </div>
        </form>
      </div>
    </MainLayout>
  );
}

export default MessageAdd;
