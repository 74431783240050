import React from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PlaylistCardSkeleton = () => {
  return (
    <SkeletonTheme baseColor="#333333" highlightColor="#444">
      <div style={{ width: "100%", height: "100%" }}>
        <Skeleton height={350} />
        <p style={{ marginTop: ".5em" }}>
          <Skeleton count={2} />
        </p>
      </div>
    </SkeletonTheme>
  );
};

export default PlaylistCardSkeleton;