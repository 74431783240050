import { useLocation, Link } from "react-router-dom"

import {
  AiFillHome,
  AiFillMessage,
  SlPlaylist,
  FaUsers
} from "../Config/icons";

import styles from "../Styles/Sidebar.module.css";

function Sidebar() {
  const location = useLocation().pathname;

  const links = [
    {
      name: "Home",
      path: "/",
      icon: <AiFillHome />
    },
    {
      name: "Users",
      path: "/users",
      icon: <FaUsers />
    },
    {
      name: "Playlists",
      path: "/playlists",
      icon: <SlPlaylist />
    },
    {
      name: "Messages",
      path: "/messages",
      icon: <AiFillMessage />
    }
  ];

  return (
    <nav className={styles.sidebar}>
      <header>
        <h1><Link to="/">MyZent Admin</Link></h1>
      </header>
      <ul>
        {links.map((link, idx) => (
          <Link key={idx} to={link.path}>
            <li className={link.path === location ? styles.active : ''}>
              <span className={styles.icon}>{link.icon}</span>
              <p className={styles.text}>{link.name}</p>
            </li>
          </Link>
        ))}
      </ul>
    </nav>
  );
}

export default Sidebar;
