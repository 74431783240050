import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

import MainLayout from '../../Layouts/MainLayout'
import { RootState } from '../../Store';
import useFetch from '../../Hooks/useFetch';

import styles from '../../Styles/Playlists.module.css';


function Users() {
  const navigate = useNavigate();
  const token: any = useSelector((state: RootState) => state.auth.token);
  const { data, error, loading }: any = useFetch('/users', token);
  const [users, setUsers] = useState<any>(null);

  useEffect(() => {
    setUsers(data);
  }, [data, setUsers]);

  const addUser = () => navigate('/users/add');

  const deleteUser = async (id: string) => {
    try {
      await axios.delete(`users/${id}`, {
        headers: {
          Authorization: token
        }
      });
      
      const new_users = users.filter((user: any) => {
        return user._id !== id;
      });

      setUsers(new_users);
    } catch (error) {
      console.error(error);
    }
  }

  const editUser = (id: string) => navigate(`/users/${id}/edit`);

  const assignPlaylists = (id: string) => navigate(`/users/${id}/playlists`);

  return (
    <MainLayout>
      <div className={styles.addNew}>
        <p>Manage Users</p>
        <button onClick={addUser} className={styles.aquaButton}>Add</button>
      </div>
      {loading ? (
        <h3>Loading...</h3>
      ) : (
        <table className={styles.table}>
          <thead>
            <tr>
              <td>#</td>
              <td>Name</td>
              <td>Email</td>
              <td>Role</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            {users && users.map((user: any, idx: number) => (
              <tr key={idx}>
                <td>{++idx}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>
                  <button onClick={() => editUser(user._id)} className={styles.aquaButton}>Edit</button>
                  <button onClick={() => deleteUser(user._id)} className={styles.deleteButton}>Delete</button>
                  <button onClick={() => assignPlaylists(user._id)} className={styles.blueButton}>Assign Playlists</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </MainLayout>
  )
}

export default Users;